import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import GlobalLayout from 'components/GlobalLayout'
import { Flex, Box } from '@rebass/emotion'
import styled from '@emotion/styled'
import { UnauthorizedPage } from 'components/Session'
import { authConditionIsUser } from 'utils/securityHelpers'
import useAuthorization from 'hooks/useAuthorization'
const Header = styled.h2`
	text-transform: uppercase;
`
function RegisterDispensaryConfirm({ location }) {
	const { isAuthorized, isLoading, authUser } = useAuthorization(authConditionIsUser)
	return (
		<GlobalLayout location={location}>
			<>
				{authUser.uid && (
					<>
						{
							isAuthorized ? (
								<Flex flexDirection="column" >
									<Box>
										<Header>Thank you for Registering Your Dispensary</Header>
									</Box>
									<Box> We will review you submission and respond back to you shortly.</Box>
									<Box py={3}>
										<Link to="/">Return to the Home Page</Link>
									</Box>
								</Flex>
							) : (
									<UnauthorizedPage />
								)
						}
					</>
				)}
			</>
		</GlobalLayout >
	)
}
RegisterDispensaryConfirm.propTypes = {
	location: PropTypes.object,
}
export default RegisterDispensaryConfirm